//import './bootstrap';
//import 'tw-elements';

// import Swiper bundle with all modules installed
//import Swiper from 'swiper/bundle';
import Swiper from 'swiper';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Carousel } from 'bootstrap';
import { Pagination } from 'swiper/modules';

Swiper.use([Pagination]);

window.Swiper = Swiper;

import Cookies from 'js-cookie';

window.Cookies = Cookies;
import { Livewire, Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm';
import collapse from '@alpinejs/collapse';

Alpine.plugin(collapse);
Livewire.start();
